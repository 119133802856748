import React, { useEffect } from "react";
import UserItem from "./UserItem";
import styled from "@emotion/styled/macro";
import { Checkbox, Divider, Fade, FormControlLabel, IconButton, Menu, MenuItem, MenuList, TextField } from "@mui/material";
import SortIcon from '@mui/icons-material/Sort';
import ClearIcon from '@mui/icons-material/Clear';
import { CheckBox, ConstructionOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";

const UserListRoot = styled.div((props) => ({
}));

const UserListHeader = styled.div((props) => ({
  
  marginBottom: "1vh",
}));

const UserListHeaderOptions = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const UserListHeaderSearch = styled.div((props) => ({}));

const UserListHeaderInfos = styled.div((props) => ({
  fontSize: "0.7rem",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}))


const StyledMenuItem = styled(MenuItem)((props) => ({
  "&.Mui-selected": {
    backgroundColor: props.theme.palette.gray.main,
    "&:hover": {
      backgroundColor: props.theme.palette.gray.main,
    }
  }
}))

const sortItems = [
  { value: "Owner AZ", title: "Eigentümer Name A-Z" },
  { value: "Owner ZA", title: "Eigentümer Name Z-A" },
  { value: "Device AZ", title: "Gerät Label A-Z" },
  { value: "Device ZA", title: "Gerät Label Z-A" },
]

const UserList = (props) => {
  
  const {users=null, results=null, presentation=false} = props;
  
  const [elements, setElements] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [sortValue, setSortValue] = React.useState("AZ");
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const menuOpen = Boolean(menuAnchor);

  const reduxVote = useSelector((state) => state.vote);

  useEffect(() =>  {
    setElements(users);
  }, [users])

  useEffect(() => {
    const filterFunc = (x) => {
      return x.owner.name.toLowerCase().includes(searchValue.toLowerCase());
    }

    var filtered = users.filter(filterFunc);
    setElements(filtered);
  }, [searchValue])

  const sorted = () => {
    const sortFunc = (a, b) => {
      switch(sortValue){
        case "Owner AZ":
          if(a.owner.name > b.owner.name) return 1;
          if(a.owner.name < b.owner.name) return -1;
          return 0;
        
        case "Owner ZA":
          if(a.owner.name < b.owner.name) return 1;
          if(a.owner.name > b.owner.name) return -1;
          return 0;

        case "Device AZ":
          if(a.device.model.title > b.device.model.title) return 1;
          if(a.device.model.title < b.device.model.title) return -1;
          return 0;

        case "Device ZA":
          if(a.device.model.title < b.device.model.title) return 1;
          if(a.device.model.title > b.device.model.title) return -1;
          return 0;
      }
    }

    var _elements = [...elements];
    _elements.sort(sortFunc);
    return _elements;
  }

  const isOnlineCount = () => {
    var filtered = users.filter((x) => x.device && x.device.connected);
    return filtered.length;
  }

  const isRegisteredCount = (presentation) => {
    var filtered;
    console.log("users", users);
    console.log("results", results);
    if(presentation){
      if(results !== null && results.resultsets.length > 0){
        filtered = users.filter((x) => (results.resultsets.findIndex((y) => y.seat === x.seat)) > -1);
      }
    } else {
      filtered = users.filter((x) => !x.away);
    }
    
    return filtered.length;
  }

  const userProps = (user) => {
    var result = null;
    var buttons = true;

    if(presentation && results !== null){
      //take resultsets
      //result = results.resultsets !== undefined && results !== null && results.result[user.seat] !== undefined ? results[user.seat] : 0;
      var index = results.resultsets.findIndex((x) => x.seat === user.seat);
      if(index >= 0){
        result = results.resultsets[index].value;
      } 
      buttons = false;
    } else {
      result = results !== null && results[user.seat] !== undefined ? results[user.seat] : null;
    }

    return {
      result: result,
      connected: null,
      device: user.device,
      owner: user.owner,
      away: user.away,
      buttons: buttons,
    }
  }

  console.log("users", users);
  console.log("results", results);

  /* 
    wenn presentation, dann nehme die übergebenen results
  */


  if(elements === null) return null;

  if(presentation){

  }

  return(
    <UserListRoot>
      <UserListHeader>
        <UserListHeaderOptions>
          <UserListHeaderSearch>
            <TextField size="small" placeholder="Suchen" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <Fade in={searchValue !== ""}>
              <IconButton onClick={() => setSearchValue("")}>
              <ClearIcon />
              </IconButton>
            </Fade>
          </UserListHeaderSearch>
          
          <IconButton onClick={(e) => setMenuAnchor(e.target)}>
            <SortIcon/>
          </IconButton>
          <Menu
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={() => setMenuAnchor(null)}
          >
            {sortItems.map((item, index) =>
              <StyledMenuItem key={index} onClick={() => setSortValue(item.value)} selected={sortValue===item.value}>{item.title}</StyledMenuItem>
            )}
          </Menu>
        </UserListHeaderOptions>
        
        <UserListHeaderInfos>
          <div>
            Teilnehmer: {users.length}
          </div>
          <div>
            Angemeldet: {isRegisteredCount(presentation)}
          </div>
          {presentation ? null :
            <>
              <div>Online: {isOnlineCount()}</div>
              
            </>
          }
         </UserListHeaderInfos>
      </UserListHeader>
       
      {sorted(elements).map((user, index) => {
        return(
          <UserItem key={index} user={user} index={index} userProps={userProps(user)} presentation={presentation} />
        );
      })}
     </UserListRoot>
  );
  

}

export default UserList;