import React, { useEffect, useRef } from "react";
import objectService from "../../services/object.service";
import GridTable from "../../layout-components/GridTable";
import GridTableHead from "../../layout-components/GridTable/GridTableHead";
import { Checkbox, FormControlLabel, IconButton, TextField, useTheme, Tooltip, Button, Modal, Fade, Box, Typography, Backdrop, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Collapse, Menu, MenuItem, Divider, ListItemIcon, ListItemText } from "@mui/material";
import voteService from "../../services/vote.service";
import MyButton from "../../layout-components/MyButton/MyButton";
import { useLocation, useNavigate } from "react-router-dom";
import SecondarySidebar from "../../layout-components/SecondarySidebar";
import styled from '@emotion/styled';
import MyPage from "../../layout-components/MyPage";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import texts from "../../texts";
import communityService from "../../services/community.service";
import AddVote from "./AddVote";
import RemoveVote from "./RemoveVote";
import SubVoteItem from "./SubVoteItem";
import { Edit } from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector, useDispatch } from "react-redux";
import RemoveMeeting from "./RemoveMeeting";
import { addAlert } from "../../redux/slices/alertsSlice";

//#region styles
const HeadvoteRoot = styled.div((props) => ({
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2rem)",
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

const HeadvoteHeader = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.theme.palette.primary.main,
  background: "white",
  background: `linear-gradient(90deg, ${props.theme.palette.primary.light} 0%, ${props.theme.palette.secondary.main} 75%)`,
  padding: "1rem",
  borderRadius: "0.5rem",
}));

const HeadvoteContent = styled.div((props) => ({
  height: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: " auto auto 2fr",
  gridTemplateAreas: `
    "buttons buttons"
    "infos object"
    "subvotes subvotes"
  `,
  gap: "1rem 1rem",
  width: "100%",
}));

const HeadVoteModule = styled.div((props) => ({
  gridArea: props.gridArea,
  border: "1px solid rgba(0, 0, 0, 0.2)",
  borderRadius: "0.5rem",
  boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.2)",
  width: "calc(100% - 2px)",
  overflow: "hidden",
}));


const HeadVoteModuleTitle = styled.div((props) => ({
  fontSize: "0.9rem",
  opacity: "0.7",
  padding: "0.5rem",

}));

const HeadVoteModuleSubVotesGrid = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "auto auto 1fr 1fr auto auto ",
  gridTemplateRows: "auto",
  gap: "0rem 0rem",
  //alignItems: "stretch",
  overflowY: "auto",
  maxHeight: "100%",
  overflowX: "hidden",
  width: "calc(100%)",
}));

const HeadVoteModuleNoSubVotes = styled.div((props) => ({
  display: "flex",
  justifyContent: "center",
  padding: "2rem",
  //border: "1px solid red",
  gridColumn: "1 / 4",

  opacity: "0.7",
  //fontStyle: "italic",
}))


const ObjectGrid = styled.div((props) => ({
  display: "flex",
  //gap: "1rem",
  flexWrap: "wrap",
  alignItems: "stretch",
  //padding: "0rem 1rem"
  //gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
}));

const InfoElement = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
  padding: "0.5rem",
}));

const InfoElementLabel = styled.div((props) => ({
  fontSize: "0.8rem",
  opacity: "0.7",
  fontStyle: "italic",
}));

const InfoElementValue = styled.div((props) => ({

}))

//#endregion

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? null : null,
});

const Meeting = (props) => {

  //#region states
  const [id, setId] = React.useState(null);
  const [vote, setVote] = React.useState(null);
  const [headvote, setHeadvote] = React.useState(null);
  const [sidebarContent, setSidebarContent] = React.useState(null);
  const [secondarySidebar, setSecondarySidebar] = React.useState(false);
  const [removeVote, setRemoveVote] = React.useState(null);
  const [communities, setCommunities] = React.useState([]);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [openLayoutDialog, setOpenLayoutDialog] = React.useState(false);
  const menuOpen = Boolean(menuAnchor);
  //#endregion

  //#region hooks
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const reduxMeeting = useSelector((state) => state.meeting);
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  useEffect(() => {
    var hash = location.hash.slice(1);
    if (hash !== "") {
      setId(hash);
    }
  }, [location.hash]);

  useEffect(() => {
    if (id !== null) {
      voteService.getOne(id)
        .then((response) => {
          setVote(response.data);
        });
    }
  }, [id])

  useEffect(() => {
    if (vote !== null) {
      setHeadvote(vote);
      communityService.getAll(vote.object)
        .then((response) => setCommunities(response.data))
        .catch((e) => console.log(e));
    }
  }, [vote])

  useEffect(() => {
    if (removeVote !== null) {
      handleSecondarySidebar("remove_vote");
    } else {
      setSecondarySidebar(false);
    }
  }, [removeVote]);

  const handleSecondarySidebarClosed = () => {
    setRemoveVote(null);
    setSecondarySidebar(false);
  }

  const handleSecondarySidebar = (type) => {

    switch (type) {
      case "vote":
        setSidebarContent(
          <AddVote
            headvote={headvote}
            setHeadvote={setHeadvote}
            handleClose={handleSecondarySidebarClosed}
            communities={communities}
          />
        );
        break;

      case "remove_vote":
        setSidebarContent(
          <RemoveVote
            headvote={headvote}
            vote={removeVote}
            setHeadvote={setHeadvote}
            setRemoveVote={setRemoveVote}
          />
        );
        break;

      case "remove_meeting":
        setSidebarContent(
          <RemoveMeeting
            meeting={vote}
          />
        );
        break;

      default:
        setSidebarContent(null);
        break;
    }

    setSecondarySidebar(true);

  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newSubvotes = reorder(
      headvote.subvotes,
      result.source.index,
      result.destination.index
    );

    var _headvote = { ...headvote };
    _headvote.subvotes = newSubvotes;

    voteService.reorderSubvotes(_headvote)
      .then((response) => {
        setHeadvote(_headvote);
      })
      .catch((e) => {
        console.log(e);
        alert("fehler");
      })

  }

  const handleExport = () => {
    //window.open("http://www.google.de", '_blank').focus();
    voteService.exportVote(vote.id)
      .then((res) => {
        //var fileURL = window.URL.createObjectURL(res);
        //let tab = window.open();
        //tab.location.href = fileURL;

        //var data = new Blob([res], {type: 'text/csv'});
        var csvURL = window.URL.createObjectURL(res);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'Ergebnisprotokoll.pdf');
        tempLink.click();

        //window.open(URL.createObjectURL(res), '_blank').focus();
      })
      .catch((e) => {
        console.log(e);
      })
  }

  const handleDeviceExport = () => {
    voteService.exportDevices(vote.id)
      .then((res) => {
        //var fileURL = window.URL.createObjectURL(res);
        //let tab = window.open();
        //tab.location.href = fileURL;

        //var data = new Blob([res], {type: 'text/csv'});
        var csvURL = window.URL.createObjectURL(res);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'Gerätezuordnung.pdf');
        tempLink.click();

        //window.open(URL.createObjectURL(res), '_blank').focus();
      })
      .catch((e) => {
        console.log(e);
      })
  }

  const handleRemove = (vote) => {
    setRemoveVote(vote);
  }

  const handleMenuItemClick = (type) => {
    switch (type) {
      case "print":
        console.log("vote", vote)
        break;

      case "edit":
        break;

      case "layout":
        setOpenLayoutDialog(true);
        break;

      case "devices":
        handleDeviceExport();
        break;

      case "results":
        handleExport();
        break;

      case "delete":
        handleSecondarySidebar("remove_meeting");
        break;
    }
    setMenuAnchor(null);
  };

  const handleStart = () => {
    if (headvote.subvotes !== null && headvote.subvotes.length > 0) {
      navigate(`/run#${headvote.id}`)
    } else {
      dispatch(addAlert({
        type: "error",
        msg: "Keine Beschlüsse hinterlegt!",
      }))
    }

  }

  const handleNewSeatlayout = () => {
    voteService.generateNewSeatlayout(headvote)
      .then((res) => {
        voteService.getOne(id)
          .then((response) => {
            setVote(response.data);
          });
      })
      .catch((e) => console.log(e));
  }

  //#endregion

  if (headvote === null) return null;

  if (reduxMeeting.id !== undefined && reduxMeeting.model !== undefined && reduxMeeting.model.processing && reduxMeeting.id === id) {
    //if(reduxMeeting && reduxMeeting.id !== undefined && reduxMeeting.id === id && reduxMeeting.model.started !== null && !reduxMeeting.model.stopped){
    navigate(`/run#${id}`);
  }

  return (
    <MyPage>
      <SecondarySidebar
        open={secondarySidebar}
        onClose={handleSecondarySidebarClosed}
        content={sidebarContent}
      />
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={openLayoutDialog}
        onClose={() => setOpenLayoutDialog(false)}
      >
        <DialogTitle style={{ display: "flex", alignItems: "center" }}>
          Teilnehmerliste
          <div style={{ marginRight: "0", marginLeft: "auto" }}>
            <Button variant="contained" onClick={handleNewSeatlayout}>Neu Generieren</Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>

          </div>
          <div>
            <GridTable cols={2}>
              <GridTableHead>
                <tr>
                  <th>Abstimmgerät</th>
                  <th>Eigentümer</th>
                </tr>
              </GridTableHead>
              <tbody>
                {headvote.seatlayout.users.map((user, index) =>
                  <tr key={index}>
                    <td>{user.deviceId !== null ? user.device.title : "-"}</td>
                    <td>{user.ownerId !== null ? user.owner.name : "-"}</td>
                  </tr>
                )}
              </tbody>
            </GridTable>
          </div>
        </DialogContent>
      </Dialog>
      <MyPageTitle size="small">
        <div style={{ display: "flex", flexDirection: "column", position: "relative", }}>
          <div style={{
            position: "absolute",
            top: "-1rem",
            fontSize: "0.8rem",
            opacity: "0.6",
            fontStyle: "italic"
          }}>
            {texts.meetings.sing}
          </div>
          <div>{headvote.title}</div>
        </div>
        <div style={{
          display: "flex",
          marginRight: "0",
          marginLeft: "auto",
          gap: "1rem",
        }}>
          <MyButton text="Zurück" color={"white"} onClick={() => { navigate(-1) }} />
        </div>
      </MyPageTitle>
      <MyPageContent style={{ display: "flex", flexDirection: "column", height: "100%", }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <HeadvoteContent>
            <HeadVoteModule gridArea="buttons">
              <div style={{
                display: "flex",
                gap: "0.5rem",
                padding: "0.5rem",
                justifyContent: "space-between"
              }}>
                <Button variant="contained" startIcon={<PlayArrowIcon />} onClick={handleStart}>
                  {texts.meetings.b_run}
                </Button>

                <Button variant="contained" startIcon={<SettingsIcon />} onClick={(e) => setMenuAnchor(e.target)}>
                  Optionen
                </Button>
                <Menu
                  anchorEl={menuAnchor}
                  open={menuOpen}
                  onClose={() => setMenuAnchor(null)}
                >
                  <MenuItem onClick={() => handleMenuItemClick("print")}>
                    <ListItemIcon>
                      <PictureAsPdfIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Print
                    </ListItemText>
                  </MenuItem>

                  <MenuItem onClick={() => handleMenuItemClick("layout")}>
                    Sitzplan anzeigen
                  </MenuItem>


                  <Divider />
                  <MenuItem onClick={() => handleMenuItemClick("devices")}>
                    <ListItemIcon>
                      <PictureAsPdfIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Gerätezuordnung
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("results")}>
                    <ListItemIcon>
                      <PictureAsPdfIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Ergebnisprotokoll
                    </ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => handleMenuItemClick("delete")}>
                    <ListItemIcon>
                      <DeleteOutlineOutlinedIcon color="error" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography color="error">Löschen</Typography>
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </div>
            </HeadVoteModule>

            <HeadVoteModule gridArea="infos">
              <HeadVoteModuleTitle>
                Details
              </HeadVoteModuleTitle>
              <ObjectGrid>
                <InfoElement>
                  <InfoElementValue>{headvote.title}</InfoElementValue>
                  <InfoElementLabel>Titel</InfoElementLabel>
                </InfoElement>
                <InfoElement>
                  <InfoElementValue>{new Date(headvote.date).toLocaleDateString()}</InfoElementValue>
                  <InfoElementLabel>geplantes Datum</InfoElementLabel>
                </InfoElement>
                <InfoElement>
                  <InfoElementValue>{headvote.type === "incognito" ? "Nein" : "Ja"}</InfoElementValue>
                  <InfoElementLabel>Namentlich</InfoElementLabel>
                </InfoElement>
              </ObjectGrid>
              <InfoElement>
                <InfoElementValue>
                  {headvote.description
                    ? <div>{headvote.description}</div>
                    : <div style={{ fontStyle: "italic" }}>Keine Beschreibung</div>
                  }
                </InfoElementValue>
                <InfoElementLabel>Beschreibung</InfoElementLabel>
              </InfoElement>
            </HeadVoteModule>

            <HeadVoteModule gridArea="object">
              <HeadVoteModuleTitle>
                {texts.objects.sing}
              </HeadVoteModuleTitle>
              <ObjectGrid>
                <InfoElement>
                  <InfoElementValue>{headvote.object.number}</InfoElementValue>
                  <InfoElementLabel>Nummer</InfoElementLabel>
                </InfoElement>

                <InfoElement>
                  <InfoElementValue>{headvote.object.name}</InfoElementValue>
                  <InfoElementLabel>Name</InfoElementLabel>
                </InfoElement>

                <InfoElement>
                  <InfoElementValue>{headvote.object.votingtype === "mea" ? "MEA" : "Anteilseigner"}</InfoElementValue>
                  <InfoElementLabel>Stimmen</InfoElementLabel>
                </InfoElement>

                <InfoElement>
                  <InfoElementValue>{headvote.object.mea}</InfoElementValue>
                  <InfoElementLabel>MEA</InfoElementLabel>
                </InfoElement>

              </ObjectGrid>

            </HeadVoteModule>

            <HeadVoteModule gridArea="subvotes" style={{ display: "flex", flexDirection: "column" }}>
              <HeadVoteModuleTitle style={{ borderBottom: "1px solid rgba(0, 0, 0, .5)" }}>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                  <div>{texts.votes.plu}</div>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    //border: "1px solid red",
                    width: "100%",
                    paddingLeft: "1rem",
                    justifyContent: "flex-end",
                  }}>
                    <Button onClick={() => handleSecondarySidebar("vote")} variant="outlined" startIcon={<AddIcon />}>{texts.votes.add}</Button>
                  </div>
                </div>
              </HeadVoteModuleTitle>

              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <HeadVoteModuleSubVotesGrid {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {/*<DragDropContext*/}

                    {headvote.subvotes.length > 0
                      ? headvote.subvotes.map((subvote, index) =>
                        <SubVoteItem subvote={subvote} key={index} index={index} handleRemove={handleRemove} meeting={headvote} />
                      )
                      : <HeadVoteModuleNoSubVotes>Keine Abstimmungen geplant!</HeadVoteModuleNoSubVotes>
                    }
                    {provided.placeholder}
                  </HeadVoteModuleSubVotesGrid>
                )}
              </Droppable>

            </HeadVoteModule>
          </HeadvoteContent>
        </DragDropContext>
      </MyPageContent>
    </MyPage>
  );

}

export default Meeting;
