import React, { useEffect } from 'react';
import {newCount} from './functions';
import styled from '@emotion/styled';
import { Button } from '@mui/material';


const MyLineChartRoot = styled.div((props) => ({
  height: "calc(100% - 2vh)",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  paddingTop: "1vh",
  paddingBottom: "1vh",
  //alignItems: "center",
  justifyContent: "center",
}))

const MyLineChartBarContainer = styled.div((props) => ({
  //height: "100%",
  width: `${props.value}%`,
  transition: "width 1s",
  display: "flex",
  //justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  border: "1px solid red",
}));

const MyLineChartBar = styled.div((props) => ({
  width: `${props.value}%`,
  backgroundColor: props.color,
  height: "30px",
  transition: "width 0.5s",
}));

const MyLineChartValueContainer = styled.div((props) => ({
  transition: "opacity 1s",
  fontSize: "1.7em",
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "center", 
  display: "flex", 
  gap: "5px", 
  overflow: "hidden", 
  justifyContent: "center",
  border: "1px solid red",
}))

const MyLineChartBarsContainer = styled.div((props) => ({
  display: "flex",
  paddingLeft: "1vh",
  paddingRight: "1vh",
}));

const MyLineChartValuesContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "1vh",
  paddingRight: "1vh",
}));

const MyLineChartValue = styled.div((props) => ({
  width: `${props.value}%`,
  minWidth: "30px",
  color: props.color,
  fontSize: `calc(1rem + ${Math.round(props.value/65)}vh)`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "width 0.5s, font-size 0.5s",
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
}));

const MyLineChart = (props) => {

  const {
    results=null, 
    users=null,
    votingtype,
    presentation,
    mea=null,
  } = props;

  const [display, setDisplay] = React.useState(votingtype);
  const [pro, setPro] = React.useState({percent: 0, mea: 0, count: 0});
  const [contra, setContra] = React.useState({percent: 0, mea: 0, count: 0});
  const [none, setNone] = React.useState({percent: 100, mea: 0, count: 0});
  const [total, setTotal] = React.useState(null);

  //var data = newCount(results, layout, done);
  //const [pros, setPros] = React.useState(0);
  //const [contras, setContras] = React.useState(0);

  /*useEffect(() => {
    setInterval(() => {
      setPros(Math.floor(Math.random() * 50));
      setContras(Math.floor(Math.random() * 50));
    }, 2000)
  }, [])*/


  useEffect(() => {

    console.log("results", results);
    console.log("users", users);
    //debugger;
    if(results !== null && users !== null){
      var totalMea = 0;

      var _pros={
        count: 0,
        percent: 0,
        mea: 0,
      };

      var _contras={
        count: 0,
        percent: 0,
        mea: 0,
      };

      var _none={
        count: 0,
        percent: 0,
        mea: 0,
      };

      for(let i=0; i<users.length; i++){
        var user = users[i];
        

        

        

        var result = null;

        if(presentation){
          var index = results.findIndex((x) => x.seat === user.seat);
          if(index >= 0){
            result = results[index].value;
          }
        } else {
          result = results[user.seat];
        }

        if(user.away && result === null) continue;

        console.log("RESULT", result);
        if(result !== null){
          totalMea+=user.mea;
          if(result > 0) {
            _pros.mea += user.mea;
            _pros.count ++;
          } else if(result < 0){
            _contras.mea += user.mea;
            _contras.count ++;
          } else if(result === 0){
            _none.mea += user.mea;
            _none.count ++;
          }
          console.log("_none", _none);
        }

      }

      console.log("mea", mea);
      if(mea !== null){
        _pros.percent = (_pros.mea / mea) *100;
        _contras.percent = (_contras.mea / mea) *100;
        //_none.mea = mea - (_pros.mea + _contras.mea);
        _none.percent = (_none.mea / mea) *100;
        //_none.count = users.length - (_pros.count + _contras.count);

      } else {
        _pros.percent = (_pros.mea / totalMea) *100;
        _contras.percent = (_contras.mea / totalMea) *100;
        _none.mea = totalMea - (_pros.mea + _contras.mea);
        _none.percent = (_none.mea / totalMea) *100;
        //_none.count = users.length - (_pros.count + _contras.count);
      }

      
    
      setPro(_pros);
      setContra(_contras);
      setNone(_none);

      setTotal(totalMea);
    } 
    
  }, [users, results]);

  useEffect(() => {
    //console.log("useEffect display");
  }, [display]);
  
  return(
    <MyLineChartRoot>
      <div style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridGap: "10px 10px",
        alignItems: "center",
        padding: "2vh",
      }}>
        <div>Pro</div>
        <MyLineChartBar color={"rgba(0, 200, 0, 0.9)"} value={pro.percent}/>
        <div>Contra</div>
        <MyLineChartBar color={"rgba(120, 0, 0, 0.5)"} value={contra.percent} />
        <div>Enthalten</div>
        <MyLineChartBar color={"orange"} value={none.percent} />
      </div>
      
      <MyLineChartValuesContainer>
        <MyLineChartValue value={pro.percent} color={"rgba(0, 200, 0, 1)"}>
          {display === "prozente"
          ? `${pro.percent.toFixed(2)}%`
          : display === "mea"
            ? pro.mea.toFixed(2)
            : display === "owner"
              ? `${pro.count}`
              : null
          }
        </MyLineChartValue>
        <MyLineChartValue value={contra.percent} color={"rgba(200, 0, 0, 1)"}>
          {display === "prozente"
          ? `${contra.percent.toFixed(2)}%`
          : display === "mea"
            ? contra.mea.toFixed(2)
            : display === "owner"
              ? `${contra.count}`
              : null
          }
        </MyLineChartValue>
        <MyLineChartValue value={none.percent} color={"rgba(0,0,0,1)"}>
          {display === "prozente"
          ? `${none.percent.toFixed(2)}%`
          : display === "mea"
            ? none.mea.toFixed(2)
            : display === "owner"
              ? `${none.count}`
              : null
          }
        </MyLineChartValue>
      </MyLineChartValuesContainer>
      <div style={{
        display: "flex",
        justifyContent: "center",
        gap: "0.5rem",
      }}>
        
        
        <Button size="small" variant='contained' color={display === "mea" ? "primary" : "gray"} onClick={ () => setDisplay("mea")}>
          MEA
        </Button>
        <Button size="small" variant='contained' color={display === "count" ? "primary" : "gray"} onClick={ () => setDisplay("owner")}>
          Stimmen
        </Button>
        <Button size="small" variant='contained' color={display === "prozente" ? "primary" : "gray"} onClick={ () => setDisplay("prozente")}>
          Prozente
        </Button>
      </div>
      
    </MyLineChartRoot>
  );

  /*
  return(
    <MyLineChartRoot>
      <MyLineChartBarsContainer>
        <MyLineChartBar color={"rgba(0, 200, 0, 0.9)"} value={pros}/>
        <MyLineChartBar color={"rgba(120, 0, 0, 0.5)"} value={contras} />
        <MyLineChartBar color={"rgba(0,0,0,0.2)"} value={(100-pros-contras).toFixed(2)} />
      </MyLineChartBarsContainer>
      <MyLineChartValuesContainer>
        <MyLineChartValue value={pros} color={"rgba(0, 200, 0, 1)"}>
          {pros.toFixed(2)}%
        </MyLineChartValue>
        <MyLineChartValue value={contras} color={"rgba(200, 0, 0, 1)"}>
          {contras.toFixed(2)}%
        </MyLineChartValue>
        <MyLineChartValue value={(100-pros-contras).toFixed(2)} color={"rgba(0,0,0,1)"}>
          {(100-pros-contras).toFixed(2)}%
        </MyLineChartValue>
      </MyLineChartValuesContainer>
      <div style={{
        display: "flex",
        justifyContent: "center",
        gap: "0.5rem",
      }}>
        
        <Button size="small" variant='contained' color={display === "prozente" ? "primary" : "gray"} onClick={ () => setDisplay("prozente")}>
          Prozente
        </Button>
        <Button size="small" variant='contained' color={display === "mea" ? "primary" : "gray"} onClick={ () => setDisplay("mea")}>
          Mea
        </Button>
        <Button size="small" variant='contained' color={display === "count" ? "primary" : "gray"} onClick={ () => setDisplay("count")}>
          Stimmen
        </Button>
      </div>
    </MyLineChartRoot>
  );*/

}

export default MyLineChart;